<template>
  <Popup ref="popupRef" width="610px" @close="resetForm" :showCancel="false" :showConfirm="false">
    <template #title>
      <div class="flex between-center">
        <PanelTitle title="动态详情" :titleStyle="{'font-size': '16px', color: '#001A18'}" style="height: auto; padding-left: 0;"/>
        <el-select :value="momentState" size="mini" class="mr40" @change="momentStateChange">
          <el-option
            v-for="item in stateList"
            :key="item.type"
            :label="item.name"
            :value="item.type">
          </el-option>
        </el-select>
      </div>
    </template>
    <div class="detail-wrap" v-if="detailData">
      <LoadImage class="avatar" :src="detailData.avatar" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
      <div class="flex-1">
        <div class="name">{{detailData.nick}}</div>
        <div class="time mt6">发布时间：{{convertDate(detailData.gmtCreate)}}</div>
        <div class="content mt8">{{detailData.content}}</div>
        <div class="img-list" v-if="detailData.mediaUrls && detailData.mediaUrls.length > 0">
          <LoadImage class="img" :src="url" v-for="(url, index) in detailData.mediaUrls" :key="index" preview></LoadImage>
        </div>
      </div>
    </div>
    <PanelTitle v-if="commontList && commontList.length > 0" class="mt10" title="评论详情" :titleStyle="{'font-size': '16px', color: '#001A18'}" style="padding-left: 0;"/>
    <div class="comment-list">
      <div class="comment" v-for="(item, index) in commontList" :key="index">
        <LoadImage class="avatar" :src="item.avatar" ></LoadImage>
        <div class="flex-1">
          <div class="flex between-center">
            <div>
              <div class="name">{{item.nick}}</div>
              <div class="time mt6">发布时间：{{convertDate(item.gmtCreate)}}</div>
            </div>
            <el-select :value="item.state" size="mini" @change="commentStateChange($event, item)">
              <el-option
                v-for="state in stateList"
                :key="state.type"
                :label="state.name"
                :value="state.type">
              </el-option>
            </el-select>
          </div>
          <div class="content mt8">{{item.commentContent}}</div>
          <div v-for="(replyItem, index) in item.replyAudits" :key="index">
            <div class="flex mt16" >
              <LoadImage class="avatar" :src="replyItem.fromAvatar"></LoadImage>
              <div class="flex flex-1 between-center">
                <div>
                  <div class="name">{{replyItem.fromNick}}</div>
                  <div class="time mt6">发布时间：{{convertDate(replyItem.gmtCreate)}}</div>
                </div>
                <el-select size="mini" :value="replyItem.state" @change="replyStateChange($event, replyItem)">
                  <el-option
                    v-for="state in stateList"
                    :key="state.type"
                    :label="state.name"
                    :value="state.type">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="content mt8">{{replyItem.replyContent}}</div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import Popup from '@/components/Popup'
import { editMomentApi, queryMomentsDetailsApi, editCommentApi, editReplyApi } from '@/api/admin.js'
import { dateFormatUtil } from '@/utils/util.js'
export default {
  components: {
    Popup,
    PanelTitle,
    LoadImage
  },
  data() {
    return {
      stateList: [
        {name:'不可见', type:0},
        {name:'自己可见', type:1},
        {name:'朋友可见', type:2},
        {name:'公开', type:3},
      ],
      commontList: [],
      momentState: 0,
      detailData: null,
      mntId: ''
    }
  },
  mounted() {
    // this.open()
  },
  methods: {
    resetForm() {
      this.detailData = null
      this.commontList = []
      this.momentState = 0
      this.mntId = ''
    },
    open(option) {
      if(option?.detail) {
        this.mntId = option.detail.mntId
        this.detailData = option.detail
        this.$refs.popupRef.open()
      }
      
      // this.getMomentDetail()
    },
    close() {
      this.$refs.popupRef.open()
    },
    momentStateChange(e) {
      console.log(e)
      const formData = {
        state: e,
        mntId: this.detailData.mntId
      }
      editMomentApi(formData).then(res => {
        this.momentState = e
        this.$tips({message: res.msg, type: 'success'})
        this.$emit('update')
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getMomentDetail() {
      console.log('mntId', this.mntId)
      queryMomentsDetailsApi({
        momentId: this.mntId
      }).then(res => {
        console.log('动态详情', res)
        if(res.data) {
          if(res.data.medias) {
            res.data.medias = res.data.medias.split(',')
          }
          this.detailData = res.data
          this.momentState = res.data.state
          this.commontList = res.data.commentAudits || []
          this.$refs.popupRef.open()
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    convertDate(date) {
      return dateFormatUtil(date, '-', true)
    },
    commentStateChange(e, info) {
      const formData = {
        cmtId: info.cmtId,
        state: e
      }
      console.log('修改comment状态', formData)
      editCommentApi(formData).then(res => {
        info.state = e
        this.$tips({message: res.msg, type: 'success'})
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    replyStateChange(e, info) {
      const formData = {
        replyId: info.replyId,
        state: e
      }
      console.log('修改reply状态', formData)
      editReplyApi(formData).then(res => {
        info.state = e
        this.$tips({message: res.msg, type: 'success'})
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrap {
  display: flex;
  font-size: 12px;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 20px;
    overflow: hidden;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
  }
  .img-list {
    display: flex;
    flex-wrap: wrap;
    .img {
      width: 68px;
      height: 68px;
      margin: 8px 8px 0 0;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
.comment-list {
  .comment {
    display: flex;
    font-size: 12px;
    margin-bottom: 16px;
    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
    .name {
      font-weight: bold;
    }
    .time {
      font-size: 10px;
    }
  }
}
</style>